<template>
  <div class="flex-row flex-hcenter">
    <h5 class="mb-1"><i>{{ company?.name }}</i>-Nutzerverwaltung</h5>
    <UserManagementButtons @createUser="onCreateUser($event)" @createInvitation="invitations.push($event)" :companyId="company?.id" />
  </div>

  <UserList noCompany @edit="onEdit" :users="users" />
  <InvitationList v-if="invitations" @delete="onInvitationDelete" :invitations="invitations" :companyId="company?.id" />
</template>

<script>
import { actions, mutations } from "@/constants.js";
import InvitationList from "@/components/InvitationList.vue";
import UserList from "@/components/UserList.vue";
import UserManagementButtons from "@/components/UserManagementButtons.vue";

export default {
  name: "CompanyManage",
  computed: {
    company() {
      return this.$store.state.user?.company;
    },
    users() {
      return this.$store.state.companyUsers;
    },
    invitations() {
      return this.$store.state.companyInvitations;
    }
  },
  methods: {
    onEdit(newData) {
      this.$store.commit(mutations.SET_COMPANY_USER, newData);
    },
    onCreateUser(user) {
      this.$store.commit(mutations.ADD_COMPANY_USER, user);
    },
    onCreateInvitation(invitation) {
      this.$store.commit(mutations.ADD_COMPANY_INVITATION, invitation);
    },
    onInvitationDelete(token) {
      this.$store.commit(mutations.DELETE_COMPANY_INVITATION, token);
    }
  },
  async mounted() {
    if (this.users === null) this.$store.dispatch(actions.GET_COMPANY_USERS);
    if (this.invitations === null && this.company) {
      const result = await this.$store.dispatch(actions.GET_COMPANY_INVITATIONS, this.company.id);
      this.$store.commit(mutations.SET_COMPANY_INVITATIONS, result === null? []: result.invitations);
    }
  },
  components: {
    InvitationList,
    UserList,
    UserManagementButtons
  }
};
</script>

<style module type="scss">

</style>
